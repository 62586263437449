import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 5
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"THREE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "Which of the following is not a physiological response to stress?"
        }
        answers={[
          "Increased heart rate",
          "Sweating",
          "Decreased heart rate",
          "Tightening muscles",
        ]}
        correctAnswerNumber={2}
        answerDetail={`When we are stressed our body responds to protect ourselves. Our heart rate increases to increase the flow of blood and oxygen to our body, we begin to sweat, and our muscles tense up to guard us from pain and injury. `}
        nextLink={nextLink}
        nextText={"RESULTS"}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 3, 5)
